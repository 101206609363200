// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-about-js": () => import("./../../../src/pages/blog/about.js" /* webpackChunkName: "component---src-pages-blog-about-js" */),
  "component---src-pages-blog-explore-js": () => import("./../../../src/pages/blog/explore.js" /* webpackChunkName: "component---src-pages-blog-explore-js" */),
  "component---src-pages-hidden-erging-classifier-js": () => import("./../../../src/pages/hidden/erging-classifier.js" /* webpackChunkName: "component---src-pages-hidden-erging-classifier-js" */),
  "component---src-pages-hidden-erging-classifier-upload-js": () => import("./../../../src/pages/hidden/erging-classifier-upload.js" /* webpackChunkName: "component---src-pages-hidden-erging-classifier-upload-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-synesthesia-js": () => import("./../../../src/pages/interactive-synesthesia.js" /* webpackChunkName: "component---src-pages-interactive-synesthesia-js" */),
  "component---src-pages-sonata-js": () => import("./../../../src/pages/sonata.js" /* webpackChunkName: "component---src-pages-sonata-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blog-post-template.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-description-template-js": () => import("./../../../src/templates/description-template.js" /* webpackChunkName: "component---src-templates-description-template-js" */)
}

